.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
}

.custom-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-modal-title {
  margin: 0;
}

.custom-close {
  background: none;
  border: none;
  font-size: 1.5rem;
}

.custom-modal-body {
  margin: 10px 0;
}

.custom-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.custom-modal-body p {
  margin-bottom: 1em;
}

.custom-modal-body p:last-child {
  margin-bottom: 0;
}
