.button {
  border: none;
  padding: 14px 24px;
  outline: none;
  font-family: inherit;
  cursor: pointer;
  border-radius: 0.5rem;
  height: 45px;
  transition: all 0.3s ease;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  width: fit-content;
}

.button.primary-light {
  color: #115a6b;
  border: 1px solid #1fa09e;
  background-color: #fff;
}

.button.primary-light:hover {
  color: #fff;
  background-color: #115a6b;
}

.button.primary-dark {
  color: #fff;
  border: 1px solid #1fa09e;
  background-color: #073247;
}

.button.primary-dark:hover {
  color: #073247;
  background-color: #fff;
}

.button.secondary-light {
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
}

.button.secondary-light:hover {
  color: #073247;
  background-color: #fff;
}

.button.secondary-dark {
  color: #073247;
  border: 1px solid #073247;
  background-color: transparent;
}

.button.secondary-dark:hover {
  color: #fff;
  background-color: #073247;
}

.btn-127w {
  width: 130.92px !important;
}
