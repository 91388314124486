.pos-rel {
  position: relative !important;
  width: 100%;
}

.service-card {
  border: 2px solid rgb(172, 220, 219, 20%) !important;
  background-color: rgb(255, 255, 255, 10%) !important;
}

.card-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  border: 1px solid rgb(172, 220, 219, 20%) !important;
  border-radius: 32px;
  background-color: rgb(255, 255, 255, 10%) !important;
  padding: 8px 16px;
}

.pb-170 {
  padding-bottom: 136px;
}

.border-t {
  border-top: 2px solid rgb(31, 160, 158, 0.3);
}

.border-b {
  border-bottom: 2px solid rgb(31, 160, 158, 0.3);
}

.theme-background {
  background: linear-gradient(to bottom, #1fa09e, #0b3d55);
}
