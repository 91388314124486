p {
  margin-bottom: 0px !important;
}

.footer-container {
  position: relative;
  width: 100%;
  background-color: #0b3d55;
  overflow: hidden;
  padding-top: 60px; /* Add padding to account for the wave height */
}

.footer-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.footer-wave svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 60px;
}

.footer-wave .shape-fill {
  fill: #ffffff;
}

.p-footer {
  font-size: 14px !important;
}

@media (max-width: 767.98px) {
  .footer-container {
    padding: 60px 24px 0px 24px; /* Adjust padding for mobile */
  }
}