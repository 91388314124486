@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300..900&family=Inter:wght@100..900&display=swap');

:root {
  /*---------------------------------------------------------------------
                                  COLORS
  -----------------------------------------------------------------------*/

  /* MAIN THEME */
  --color-primary-teal: #1FA09E
  --color-primary-dark-teal: #115A6B
  --color-secondary-dark-blue: #0B3D55
  --color-secondary-darkest-blue: #073247
  --color-purple: #8E347E
  --color-black: #252525


  /* NEUTRAL */
  --N700: #353439;
  --N600: #5f5e64; 
  --N500: #807e86;
  --N400: #adacb1;
  --N300: #dad9de;
  --N200: #f0eff4;
  --N100: #f8f7fb;
  --N000: #ffffff;

  /*---------------------------------------------------------------------
                                  TEXT
  -----------------------------------------------------------------------*/

  --bold: 600;
  --semibold: 500;
  --regular: 400;
  --light: 300;

  --xlarge: 1.5rem;
  --large: 1.2rem;
  --medium: 1rem;
  --small: 0.875rem;
  --xsmall: 0.75rem;
}

body {
  font-family: 'Inter' !important;
  font-weight: 300 !important;
}

h2 {
  font-size: 18px !important;
}
/* 
.container {
  max-width: 1120px !important;
} */

.pb-6 {
  padding-bottom: 64px !important;
}

.card-custom {
  color: #115A6B;
  padding: 24px 16px 40px 16px;
  border: 2px solid #ACDCDB;
  border-radius: 8px;
  background-color: #E9F5F5;
  height: 337px;
  width: 262px;
  position: relative;
}

.card-custom:hover {
  cursor: pointer;
}

.card-custom::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #ACDCDB;
  transition: height 0.3s ease;
}

.card-custom.active::after {
  height: 20px;
}


.logo {
  height: 60px !important;
}

.bg-body-tertiary {
  background-color: none !important;
}

.height-400 {
  height: 380px !important;
}

.home-image {
  height: 100%;
  background-image: url('./assets/images/home-staff-group.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.staff-theme-background {
  background: linear-gradient(58deg, rgba(11,61,85,1) 0%, rgba(31,160,158,1) 100%);
  border-radius: 8px;
}

.theme-background {
  background: linear-gradient(80deg, rgba(11,61,85,1) 0%, rgba(31,160,158,1) 100%);
}

.theme-background-2 {
  background: linear-gradient(80deg, rgba(31,160,158,1) 0%, rgba(11,61,85,1) 100%);
}

.br-8 {
  border-radius: 8px !important;
}

.waves-multiple {
  background-color: red;
}

.font-frl {
  font-family: 'Frank Ruhl Libre';
}

.font-db {
  color: #0B3D55 !important;
}

.font-db-1 {
  color: #0B3D55 !important;
  opacity: 60% !important;
}

.custom-container-1280 {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.custom-g-145 {
  gap: 145px !important;
}

.p-32 {
  padding: 32px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.mb-104 {
  margin-bottom: 104px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.pr-special {
  padding-right: 72.5px !important;
}

.pl-special {
  padding-left: 72.5px !important;
}

.pr-special-36 {
  padding-right: 36px !important;
}

.pl-special-36 {
  padding-left: 36px !important;
}

.pr-special-43 {
  padding-right: 43.5px !important;
}

.pl-special-43 {
  padding-left: 43.5px !important;
}

.pr-special-56 {
  padding-right: 56px !important;
}

.pl-special-56 {
  padding-left: 56px !important;
}

@media (max-width: 767.98px) {
  .mb-48 {
    margin-bottom: 0px !important;
  }

  .mb-104 {
    margin-bottom: 48px !important;
  }

  .mb-80 {
    margin-bottom: 32px !important;
  }

  .card-custom {
    padding: 24px 16px 24px 16px;
    height: auto;
  }

  .pr-special {
    padding-right: 0px !important;
  }
  
  .pl-special {
    padding-left: 0px !important;
  }

  .pr-special-56 {
    padding-right: 0px !important;
  }
  
  .pl-special-0 {
    padding-left: 56px !important;
  }

  .card-custom {
    width: 100%;
  }
}

.custom-gutter {
  margin: 1.75rem; /* Adjust this value as needed */
}

.button {
  border: none;
  padding: 0;
  outline: none;
  font-family: inherit;
  cursor: pointer;
  border-radius: 0.5rem;
  height: 45px;
  padding: 14px 24px;
  transition: all 0.3s ease;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
}

.button.primary-light {
  color: #115a6b;
  border: 1px solid #1fa09e;
  background-color: #fff;
}

.button.primary-light:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.container {
  width: 100% !important;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px !important;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1120px !important;
  }
}

.treatment-logo {
  height: 128px;
}

.mr-10 {
  margin-right: 10px;
}