.nav-container {
  background-image: url('../../assets//waves/main-wave.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 600px;
  width: 100%;
  overflow: hidden;
}

.sine-wave-nav {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
}

.fullscreen-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(20deg, rgba(11, 61, 85, 1) 0%, rgba(31, 161, 159, 1) 100%);
  z-index: 999;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 0px 0px 44px;
}

.fullscreen-nav .navbar-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.fullscreen-nav.show {
  display: flex;
}

.nav-link {
  font-size: 14px !important;
  position: relative !important;
  display: inline-block !important;
}

.fullscreen-nav .nav-link {
  color: white !important;
  font-size: 18px !important;
  margin: 10px 0;
  font-weight: 600 !important;
}

.nav-link::after {
  content: '' !important;
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: 80% !important;
  bottom: 2px !important; /* Adjust this value to move the underline closer or further from the text */
  height: 2px !important; /* Adjust this value for the thickness of the underline */
  background-color: #0b3d55 !important; /* This will use the current text color */
  opacity: 0 !important;
  transition: opacity 0.3s ease !important;
}

.fullscreen-nav .nav-link::after {
  width: 100% !important;
  background-color: #fff !important;
  height: 3px !important;
}

.nav-link:hover::after {
  opacity: 1 !important;
}

.nav-light {
  color: #fff !important;
  font-weight: 600 !important;
}

.nav-light:hover {
  color: #0b3d55 !important;
}

.nav-dark {
  color: #0b3d55 !important;
  font-weight: 600 !important;
}

.background {
  background-color: #0b3d55;
}

.pp-wave {
  height: 100px;
  overflow: hidden;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.nav-link.active-custom::after {
  opacity: 1 !important;
}
